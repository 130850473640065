<script setup lang="ts">
const customizer = useCustomizerStore();
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
    <v-app :theme="customizer.actTheme">
        <div class="d-flex justify-center align-center text-center h-100">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-h1 pt-3 text-primary">¡Upps!</h1>
                    <img src="/images/backgrounds/errorimg.svg" width="340" alt="404" />
                    <p class="text-h4 my-8 px-14 font-weight-light text-primary">{{ $t('errors.This page you are looking for could not be found') }}</p>
                    <v-btn flat color="primary" class="mb-4" @click="navigateTo('/')">{{ $t('Back')}}</v-btn>
                </v-col>
            </v-row>    
        </div>
    </v-app>
</template>
