import revive_payload_client_4sVQNw7RlN from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AUP22rrBAc from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import vue_qrcode_reader_gPmnxGxZrO from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vue-qrcode-reader.js";
import mercadopago_B4Pgx8ZaG3 from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/mercadopago.js";
import nuxtReloadAttempt_uPpS0qCWqe from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/nuxtReloadAttempt.js";
import pinia_48xmdi2HHl from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/pinia.ts";
import safeArea_X6SMFGWdsL from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/safeArea.ts";
import vee_validate_K3WwmJMPDb from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vee-validate.js";
import vue_qrcode_OjNsPZPw7E from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vue-qrcode.ts";
import vuetify_7h9QAQEssH from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AUP22rrBAc,
  vue_qrcode_reader_gPmnxGxZrO,
  mercadopago_B4Pgx8ZaG3,
  nuxtReloadAttempt_uPpS0qCWqe,
  pinia_48xmdi2HHl,
  safeArea_X6SMFGWdsL,
  vee_validate_K3WwmJMPDb,
  vue_qrcode_OjNsPZPw7E,
  vuetify_7h9QAQEssH
]