import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import PerfectScrollbar from "vue3-perfect-scrollbar";
// import VueTablerIcons from '@tabler/icons-vue';
//Mock Api data
import vue3GoogleLogin from 'vue3-google-login'

import Vue3Lottie from "vue3-lottie";
import "@/assets/scss/style.scss";


// Table
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

//i18
import { createI18n } from "vue-i18n";
import messages from "@/utils/locales/messages";


import {
  // BLUE_THEME,
  // AQUA_THEME,
  // PURPLE_THEME,
  // GREEN_THEME,
  // CYAN_THEME,
  ORANGE_THEME,
} from "@/theme/LightTheme";
import {
  // DARK_BLUE_THEME,
  // DARK_AQUA_THEME,
  // DARK_PURPLE_THEME,
  // DARK_GREEN_THEME,
  // DARK_CYAN_THEME,
  DARK_ORANGE_THEME,
} from "@/theme/DarkTheme";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: false,
    //blueprint: md3,
    components: {
      // draggable: VueDraggableNext,
      ...components,
    },
    directives,
    theme: {
      defaultTheme: "ORANGE_THEME",
      variations: {
        colors: ['primary', 'secondary', 'grey', 'success', 'warning', 'error', 'white'],
        lighten: 5,
        darken: 5,
      },
      themes: {
        // BLUE_THEME,
        // AQUA_THEME,
        // PURPLE_THEME,
        // GREEN_THEME,
        // CYAN_THEME,
        ORANGE_THEME,
        // DARK_BLUE_THEME,
        // DARK_AQUA_THEME,
        // DARK_PURPLE_THEME,
        // DARK_GREEN_THEME,
        // DARK_CYAN_THEME,
        DARK_ORANGE_THEME,
      },
    },
    defaults: {
      VLabel: {
        style: 'font-weight: 600 !important'
      },
      VCard: {
        rounded: "lg",
        elevation: 0,
        style: 'background-color: rgb(var(--v-theme-containerBg))'
      },
      VOverlay: {
        scrim: 'surface'
      },
      VSkeleton: {
        scrim: 'surface'
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VTextarea: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VSelect: {
        variant: "outlined",
        density: "comfortable",
        color: "primary",
      },
      VListItem: {
        minHeight: "45px",
      },
      VTooltip: {
        location: "top",
      },
      VBtn: {
        style: "text-transform: capitalize; letter-spacing:0",
        rounded: "xl",
      },
    },
  });

  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "es",
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
  });
  nuxtApp.vueApp.use(vuetify);
  nuxtApp.vueApp.component("EasyDataTable", Vue3EasyDataTable);
  nuxtApp.vueApp.use(PerfectScrollbar);
  
  nuxtApp.vueApp.use(vue3GoogleLogin, {
    clientId: '479561457678-26q7naueel2vve9orjl4jdmjk5n866fs.apps.googleusercontent.com'
  })
  
  // nuxtApp.vueApp.use(VueTablerIcons);
  nuxtApp.vueApp.use(VueTelInput);
  nuxtApp.vueApp.use(i18n);
  nuxtApp.vueApp.use(Vue3Lottie)
});
