import { defineStore } from 'pinia';
import productImage from '/images/product_categories/OTROS.png';
import { useStoresStore } from '@/stores/apps/store';

// project imports
import axios from '@/utils/axios';
// types
import type { ProductStateProps, Products } from '@/types/apps/EcommerceType';
import { recategorizeData, categoriesImages } from '@/types/apps/EcommerceType';
import { filter, map, sum, unescape } from 'lodash';


export const useEcomStore = defineStore({
    id: 'eCommerce',
    state: (): ProductStateProps => ({
        cart: [],
        price: 0,
        subTotal: 0,
        discount: 0,
        discountCard: 0,
        taxes: 0,
        total: 0,
        totalQty: 0,
        activeCard: { id: null, type: null, number: null },
        paymentMethod: null,
    }),
    getters: {
        getTotals(state) {
            return {
                total: state.total,
                subTotal: state.subTotal,
                discount: state.discount,
                discountCard: state.discountCard,
                taxes: state.taxes,
                qty: state.totalQty,
            }
        },
        getCart(state) {
            return state.cart;
        },
        getTotalQty(state) {
            return state.totalQty;
        }
    },
    actions: {
        // Fetch Customers from action
        async fetchProducts() {
            try {
                const data = await axios.get('/api/products/list');
                this.products = data.data;
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updateItem(item: Products){
            try {
                const updatedProduct = await this.fetchProduct(item, true);
                Object.assign(item, updatedProduct, {
                    added: new Date().toISOString().split('T')[0]//.replace(/\D/g, '') // Fecha actualizada
                });
            } catch (err) {
                this.deleteProduct(item.pk)
            }
        },
        async validateProducts(){
            const today = new Date().toISOString().split('T')[0]// .replace(/\D/g, '');
            for (const item of this.cart) {
                if (item.added !== today) {
                    await this.updateItem(item);
                }
            }
            return this.cart
        },
        // Fetch product
        async fetchProduct(product: string | number | Products, returnItem?: boolean) {
            // console.log("Fetch Product:", product, typeof product)
            if (typeof product === 'undefined'){
                console.error("Product is undefined!!", typeof product, product)
                // TODO: Mensaje de error advirtiendo que no llegó el item (esto no debería suceder)
                throw new Error('Product is undefined!');
            } else if (typeof product === 'string' || typeof product === 'number') {
                if (typeof product === 'string' && product.trim() === ''){
                    console.error("Product is empty!!", typeof product, product)
                    throw new Error('Product is empty!');
                }
                let founded = false;
                const updateCart = map(this.cart, (item: any) => {
                    if (item.barcode === product && item.unit !== 'KG') {
                        founded = true;
                        if (returnItem){
                            return item;
                        }
                        return {
                            ...item,
                            qty: item.qty + 1
                        };
                    }
                    return item;
                });
                if (founded) {
                    this.cart = updateCart;
                    this.updateAll()
                    return true;
                }
            }
            const baseUrl = useRuntimeConfig().public.apiBase;
            const storeId = useStoresStore().selectedStore.id;
            try {
                let response = await fetchWrapper.get(`${baseUrl}/api/v1/management/stores/${storeId}/products/${typeof product === 'string' || typeof product === 'number' ? product : product.barcode}?_=${new Date().getTime()}`);
                if (response){
                    const findCategory = recategorizeData.find(data => data.category === response.category)
                    const newCategory = findCategory && findCategory.group ? findCategory.group : response.category
                    const findImage = categoriesImages.find(data => data.category === newCategory)
                    const categoryImage = findImage && findImage.image ? findImage.image : productImage
                    // if (response.ean === '7509552848076') response.discount = 0 // temp hardcoded discount
                    const productFound = {
                            id: response.id,
                            sku: response.sku,
                            barcode: response.ean,
                            readedCode: response.ean,
                            name: response.name,
                            categories: [findCategory?.group],
                            price: parseFloat(response.price),
                            salePrice: parseFloat(response.price),
                            offerPrice: parseFloat(response.discount) > 0 ? parseFloat(response.price) * (1 - (parseFloat(response.discount) / 100)) : undefined,
                            offerCardPrice: (parseFloat(response.discount_card) - parseFloat(response.discount)) > 0 ? parseFloat(response.price) * (1 - ((parseFloat(response.discount_card) - parseFloat(response.discount)) / 100)) : undefined,
                            qty: 1,
                            tax: parseFloat(response.iva),
                            unit: response.und.toUpperCase().trim(),
                            discount: parseFloat(response.discount) ?? 0,
                            discountCard: parseFloat(response.discount_card) ?? 0,
                            image: categoryImage,
                            added: new Date().toISOString().split('T')[0]
                        };
                    if (typeof product === 'string' || typeof product === 'number') {
                        if (returnItem){
                            return productFound;
                        }
                        this.addToCart(productFound);
                    } else {
                        throw new Error('Product typeof unrecognized: ' + typeof product);
                        // return productFound
                    }
                } else {
                    throw new Error('Product not found');
                }
            } catch(e){
                // PESO VARIABLE:
                let barcode = typeof product === 'string' || typeof product === 'number' ? product.toString() : product.barcode;
                if (typeof barcode === 'string' && ['21','27','28','29'].includes(barcode.substring(0,2)) ) {
                    let newBarCode = barcode.substring(0, 7);
                    let quantity = parseFloat(barcode.substring(barcode.length - 6, barcode.length - 4) + '.' + barcode.substring(barcode.length - 4, barcode.length -1 ));
                    let response = await fetchWrapper.get(`${baseUrl}/api/v1/management/stores/${storeId}/products/${newBarCode}`);
                    if (response){
                        const findCategory = recategorizeData.find(data => data.category === response.category)
                        const newCategory = findCategory && findCategory.group ? findCategory.group : response.category
                        const findImage = categoriesImages.find(data => data.category === newCategory)
                        const categoryImage = findImage && findImage.image ? findImage.image : productImage
                        // if (response.ean === '7509552848076') response.discount = 0 // temp hardcoded discount
                        const productFound = {
                                id: response.id,
                                sku: response.sku,
                                barcode: response.ean,
                                readedCode: barcode,
                                name: response.name,
                                categories: [findCategory?.group],
                                price: parseFloat(response.price),
                                salePrice: parseFloat(response.price),
                                offerPrice: parseFloat(response.discount) > 0 ? parseFloat(response.price) * (1 - (parseFloat(response.discount) / 100)) : undefined,
                                offerCardPrice: (parseFloat(response.discount_card) - parseFloat(response.discount)) > 0 ? parseFloat(response.price) * (1 - ((parseFloat(response.discount_card) - parseFloat(response.discount)) / 100)) : undefined,
                                qty: quantity,
                                tax: parseFloat(response.iva),
                                unit: response.und.toUpperCase().trim(),
                                discount: parseFloat(response.discount) ?? 0,
                                discountCard: parseFloat(response.discount_card) ?? 0,
                                image: categoryImage,
                                isPackagingItem: false,
                                added: new Date().toISOString().split('T')[0]
                            };
                        console.info("productFound!", productFound)
                        if (typeof product === 'string' || typeof product === 'number') {
                            if (returnItem){
                                return productFound;
                            }
                            this.addToCart(productFound)
                        } else {
                            throw new Error('Product typeof unrecognized: ' + typeof product);
                            // return productFound
                        }
                    } else {
                        // TODO: Mensaje de error advirtiendo que no se encontró el item buscado
                        console.error("Product not found1: ", barcode.toString())
                        throw new Error('Product not found');
                    }
                } else {
                    // TODO: Mensaje de error advirtiendo que no se encontró el item buscado
                    console.error("Product not found2: ", product.toString())
                    throw new Error('Product not found');
                }
            }
        },
        setActiveCard(card: object){
            // console.log("activeCard: ", card)
            this.activeCard = card;
        },
        setPaymentMethod(paymentMethod: string){
            this.paymentMethod = paymentMethod;
            if (paymentMethod === 'Other'){
                this.activeCard = { id: null, type: null, number: null }
            }
        },
        async addToCart(item: any) {
            const product = { ...item, pk: this.cart.length + 1 };
            this.cart = [...this.cart, product];
            this.updateAll()
            return product
        },
        //qty
        async incrementQty(productPk: number) {
            let foundProduct = null;
            const updateCart = map(this.cart, (product: any) => {
                if (product.pk === productPk) {
                    foundProduct = product;
                    return {
                        ...product,
                        qty: product.qty + 1
                    };
                }
                return product;
            });
            this.cart = updateCart;
            this.updateAll()
            return foundProduct;
        },
        //qty
        async decrementQty(productPk: any) {
            let foundProduct = null;
            const updateCart = map(this.cart, (product: any) => {
                if (product.pk === productPk) {
                    foundProduct = product;
                    return {
                        ...product,
                        qty: product.qty - 1
                    };
                }
                return product;
            });
            this.cart = updateCart;
            this.updateAll()
            return foundProduct;
        },
        async updateAll(){
            await this.getSubTotal()
            await this.getDiscount()
            await this.getTaxes()
            await this.getTotal()
        },
        // delete Product
        async deleteProduct(productPk: any) {
            // console.log("Entra a deleteProduct()");
            const updateCart = filter(this.cart, (p:any) => p.pk !== productPk);
            this.cart = updateCart;
            this.updateAll()
        },
        // delete Cart
        async deleteCart(from="") {
            console.log(`Entra a deleteCart(from=${from})`);
            this.cart = [];
            useInvoiceStore().setInvoice('');
            useInvoiceStore().setPaymentStatus('');
            this.updateAll()
        },
        //subtotal
        async getSubTotal() {
            // console.log("Entra a getSubTotal()", this.cart);
            this.subTotal = sum(this.cart.map((product: any) => product.salePrice * product.qty));
            // console.info("Subtotal: " + this.subTotal)
        },
        //discount
        async getDiscount() {
            // console.log("Entra a getDiscount()");
            this.discount = this.subTotal - sum(this.cart.map((product: any) => (product.offerPrice ?? product.salePrice) * product.qty));
            // this.discountCard = this.subTotal - sum(this.cart.map((product: any) => (product.offerCardPrice ?? product.salePrice) * product.qty));
            // TODO: Temporal discountCard:
            this.discountCard = this.subTotal - sum(this.cart.map((product: any) => (product.offerCardPrice ?? product.salePrice) * product.qty));
            // console.info("Discounts =>  Normal:" + this.discount + ", Card:" + this.discountCard)
        },
        // taxes
        async getTaxes(){
            this.taxes = this.subTotal - (this.discount??0) - sum(this.cart.map((product: any) => (product.offerPrice ?? product.salePrice) * product.qty / (1 + (product.tax / 100))));
        },
        //total
        async getTotal() {
            // console.log("Entra a getTotal()", this.cart);
            if (this.subTotal !== undefined && this.discount !== undefined) {
                this.total = this.subTotal - this.discount;
                this.totalQty = sum(this.cart.map((product: { categories: string | string[]; unit: string; qty: any; }) => !product.categories.includes('BOLSAS') ? product.unit.toUpperCase().trim() === 'UND' ? product.qty : 1 : 0));
            } else {
                this.total = 0;
                this.totalQty = 0;
            }
            return this.total;
            // console.info("Total: " + this.total)
        },
        findProductOnCart(code: string){
            return this.cart.find((product: any) => product.barcode === code);
        },
        async createPayment(payload:any) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            return fetchWrapper.post(`${baseUrl}/api/v1/management/payments/mercado-pago/create_payment/`, payload )
                .then((data)=> {
                    console.info(data)
                    return { ...data.message, status: data.status, id: data.message.id }
                })
                .catch(error => {
                    throw error;
                })
        },
    },
    persist: {
        storage: persistedState.sessionStorage,
    },
});
