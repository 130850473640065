import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const BLUE_THEME: ThemeTypes = {
    name: 'BLUE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 1,
    },
    colors: {
        primary: '#5D87FF',
        secondary: '#49BEFF',
        info: '#539BFF',
        success: '#13DEB9',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#C54966',
        grey: '#F2F6FA',
        lightprimary: '#ECF2FF',
        lightsecondary: '#E8F7FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        grey600: '#909090',
        grey800: '#989898'
    }
};

const AQUA_THEME: ThemeTypes = {
    name: 'AQUA_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 1,
    },
    colors: {
        primary: '#0074BA',
        secondary: '#47D7BC',
        info: '#539BFF',
        success: '#13DEB9',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#C54966',
        grey: '#CCCCCC',
        lightprimary: '#EFF9FF',
        lightsecondary: '#EDFBF7',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};

const PURPLE_THEME: ThemeTypes = {
    name: 'PURPLE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 1,
    },
    colors: {
        primary: '#763EBD',
        secondary: '#95CFD5',
        info: '#539BFF',
        success: '#13DEB9',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#C54966',
        lightprimary: '#F2ECF9',
        lightsecondary: '#EDF8FA',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};

const GREEN_THEME: ThemeTypes = {
    name: 'GREEN_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 1,
    },
    colors: {
        primary: '#0A7EA4',
        secondary: '#CCDA4E',
        info: '#539BFF',
        success: '#13DEB9',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#C54966',
        lightprimary: '#F4F9FB',
        lightsecondary: '#FAFBEF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};

const CYAN_THEME: ThemeTypes = {
    name: 'CYAN_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 1,
    },
    colors: {
        primary: '#01C0C8',
        secondary: '#FB9678',
        info: '#539BFF',
        success: '#00e676',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#C54966',
        lightprimary: '#EBF9FA',
        lightsecondary: '#FFF5F2',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};

const ORANGE_THEME: ThemeTypes = {
    name: 'ORANGE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 0.4,
    },
    colors: {
        primary: '#FA896B',
        secondary: '#0074BA',
        info: '#539BFF',
        success: '#00e676',
        accent: '#FFAB91',
        warning: '#ffe57f',
        error: '#C54966',
        lightprimary: '#FBF2EF',
        lightsecondary: '#EFF9FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f9f9f9',
        hoverColor: '#f6f9fc',
        surface: '#ffffff',
        'on-surface': '#3f3f3f',
        'on-surface-variant': '#3f3f3f',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        grey600: '#909090',
        grey800: '#989898',
    }
};

export { BLUE_THEME, AQUA_THEME, ORANGE_THEME, PURPLE_THEME, GREEN_THEME, CYAN_THEME };
